import { Flex, Box } from '@chakra-ui/react';
import React from 'react';
import { Text, Header } from '../components/Common/Brand';

const NotFoundPage = () => (
  <Flex
    direction="column"
    gap={5}
    p={[1, 5]}
    maxWidth="lg"
    m="auto"
    my={40}
    color="brown.1"
  >
    <Header size="h1">404: Not Found</Header>
    <Text size="p1">You just hit a route that doesn&#39;t exist...</Text>
  </Flex>
);

export default NotFoundPage;
